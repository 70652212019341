import * as React from 'react';
import getHref from '../../util/getHref';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Prefs } from '../../App';
import { login, resetTeacherUserPassword } from '../../service';
import * as Yup from 'yup';

import { PageLayout } from '../page-layout';
import { usePlaying } from '../../context/playing';
import { EmphasisTitle } from '../common/EmphasisTitle';
import { InputForm } from '../Input-form';
import { Paragraph } from '../common/Paragraph';
import Button from '../common/Button';
import { SocialLogin } from '../common/social-login';
import { ResetPasswordModal } from '../common/ResetPasswordModal';
import { IModalRef } from '../common/Modal';
import { FormikValues, useFormik } from 'formik';

enum LoginErrorType {
  USER,
  DEFAULT,
}

const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required('Campo obrigatório')
    .min(8, 'A senha deve ter no mínimo 8 caracteres'),
  confirmPassword: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password')], 'As senhas não coincidem'),
});
export const Login: React.FC = () => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const [loginError, setLoginError] = React.useState<boolean>(false);
  const [resetPasswordMessage, setResetPasswordMessage] = React.useState<string>('');
  const [errorType, setErrorType] = React.useState<LoginErrorType>(LoginErrorType.DEFAULT);
  const navigate = useNavigate();
  const { origin } = usePlaying();

  const resetPasswordModalRef = React.useRef<IModalRef>(null);

  if (localStorage.getItem('token')) {
    localStorage.clear();
  }

  React.useEffect(() => {
    setTimeout(() => {
      setResetPasswordMessage('');
    }, 4000);
  }, [resetPasswordMessage]);

  const authenticate = React.useCallback(
    (token: string) => {
      const jwt_values: Prefs = jwt(token);
      localStorage.setItem('token', token);
      if (jwt_values.avatar && jwt_values.avatar !== '') {
        localStorage.setItem('avatar', jwt_values.avatar);
        localStorage.setItem('email', jwt_values.email);
        localStorage.setItem('avatarName', jwt_values.avatarName || '');
        window.dispatchEvent(new Event('login'));
      } else {
        return navigate(getHref('choose-avatar'));
      }

      if (jwt_values.city && jwt_values.city !== '') {
        localStorage.setItem('city', jwt_values.city);
        localStorage.setItem('game', jwt_values.game || '');
      } else {
        return navigate(getHref('choose-city'));
      }
      setLoginError(false);

      return navigate(getHref(''));
    },
    [navigate],
  );

  const handleLoginButtonPressed = async () => {
    try {
      setIsLoading(true);
      localStorage.clear();
      const jwt_token = await login(email, password);
      if (!jwt_token.data.auth && jwt_token.data.needsToChangePassword) {
        formikResetPassword.resetForm({
          values: {
            userId: jwt_token.data.userId,
            validationCode: jwt_token.data.validationCode,
            password: '',
            confirmPassword: '',
          },
        });
        return resetPasswordModalRef?.current?.handleModal();
      }
      authenticate(jwt_token.data.token);
    } catch (error: any) {
      console.error({ error });

      if (error.response?.data?.message === 'User not found') setErrorType(LoginErrorType.USER);
      if (error.response?.data?.message === 'Invalid user or password')
        setErrorType(LoginErrorType.DEFAULT);

      setLoginError(true);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!loginError) return;
    setTimeout(() => {
      setLoginError(() => {
        setErrorType(LoginErrorType.DEFAULT);
        return false;
      });
    }, 4000);
  }, [loginError]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLoginButtonPressed();
  };

  const handleResetPassword = React.useCallback(
    async (values: FormikValues) => {
      try {
        setIsLoading(true);
        const result = await resetTeacherUserPassword(
          values.userId,
          values.validationCode,
          values.password,
        );
        if (result?.data) {
          setResetPasswordMessage('Senha alterada com sucesso!');
          setTimeout(() => {
            setResetPasswordMessage('');
            authenticate(result.data.token);
            resetPasswordModalRef?.current?.handleModal();
          }, 4000);
        }
      } catch (err: any) {
        if (err.response?.data?.message === 'Código inválido.')
          setResetPasswordMessage(
            'Falha ao alterar a senha, peça para o professor executar esta ação novamente.',
          );
      } finally {
        setIsLoading(false);
      }
    },
    [authenticate],
  );

  const formikResetPassword = useFormik({
    initialValues: {
      userId: '',
      validationCode: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleResetPassword,
    validationSchema: resetPasswordSchema,
  });

  return (
    <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8">
      <div className="mx-0 flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center sm:w-[45rem]">
          <img
            src={
              origin.includes('neoenergia')
                ? '/img/rockinrio/logo-rock-neo.png'
                : '/svg/cidadeazullogo.svg'
            }
            alt="logo"
            className="h-72 w-72 object-contain"
          />
          <EmphasisTitle className="mb-8 mt-4 text-secondary">Entre com sua conta:</EmphasisTitle>
          <form
            onSubmit={handleSubmit}
            className="flex w-full flex-col items-center justify-center gap-6"
          >
            <InputForm
              className="px-8 text-start"
              name="email"
              label="Email"
              placeholder="Email ou Nickname"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <InputForm
              className="px-8 text-start"
              name="password"
              label="Senha"
              placeholder="Senha"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            {loginError && (
              <Paragraph className="mt-6 text-center font-semibold text-[#DE3838]">
                {errorType === LoginErrorType.USER &&
                  'Olá! Este email/nickname não está cadastrado!'}
                Olá! O email/nickname ou senha é inválido. Tente novamente.
              </Paragraph>
            )}
            <Button
              type="submit"
              className="w-full"
              value={!loading ? 'Entrar' : 'Carregando...'}
              inactive={loading}
            />
          </form>
          <a
            href={getHref('recover-password')}
            className="mt-6 text-2xl font-bold text-secondary md:text-3xl"
          >
            Esqueceu sua senha? Clique Aqui.
          </a>
          <SocialLogin setLoginError={setLoginError} hasAccount={false} />
          <ResetPasswordModal
            ref={resetPasswordModalRef}
            loading={loading}
            formik={formikResetPassword}
            message={resetPasswordMessage}
          />
        </div>
      </div>
    </PageLayout>
  );
};
