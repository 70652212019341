export const validateVideoLink = (url: string): string => {
  if (!url) return 'Link do vídeo inválido';
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.+$/;

  if (youtubeRegex.test(url)) return 'youtube';
  if (tiktokRegex.test(url)) return 'tiktok';

  return 'Link do vídeo inválido';
};
